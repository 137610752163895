import { useMutation } from '@tanstack/react-query';
import {
  AnalyticsCartEventNames,
  DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT,
  getAnalyticsEventAttributes,
  trackCartEvent,
} from '@tectonic/analytics';
import { remixApi } from '@tectonic/api-client';
import { getErrorMessage } from '@tectonic/errors';
import { script } from '@tectonic/halo-script';
import { Logger } from '@tectonic/logger';
import { ElemasonWidgetActionType } from '@tectonic/types';
import { access } from '@tectonic/utils';
import { useRef } from 'react';
import { Button } from '../../../components';
import {
  ElemasonAnalyticsProvider,
  useElemasonAnalyticsContext,
} from '../../../contexts';
import { ActionDispatchExporter } from '../../../core';
import { useToast } from '../../../core/ElemasonEntry/Toast';
import { useHaloScript } from '../../../hooks/useHaloScript';
import { enrichAnalyticsPayloadWithWidgetData } from '../../../utils';

import type { AnalyticsCartEventPayload } from '@tectonic/analytics';
import type {
  Cart,
  CartAnalyticsAttribute,
  ElemasonExpressCheckoutWidget,
} from '@tectonic/types';
import type { FC } from 'react';
import type { DispatchExporterHandle } from '../../../core';

const IS_ANONYMOUS_ACCESSOR = script([
  access(['page', 'currentUser', 'isAnonymous']),
]);

interface ElemasonExpressCheckoutWidgetProps {
  widget: ElemasonExpressCheckoutWidget;
}

const ExpressCheckoutWidget: FC<ElemasonExpressCheckoutWidgetProps> = ({
  widget,
}) => {
  const { data, config } = widget;
  const { showToast } = useToast();
  const product = useHaloScript(data?.product);
  const variant = useHaloScript(data?.variant);
  const isAnonymous = useHaloScript<boolean>(IS_ANONYMOUS_ACCESSOR);
  const addressId = useHaloScript(data?.addressId);
  const discountCodes = useHaloScript<string[]>(data?.discountCodes);
  const giftCardReceiver = useHaloScript(data?.giftCardReceiver);
  const helperCtaRef = useRef<HTMLSpanElement>(null);

  const analyticsContext = useElemasonAnalyticsContext();
  const dispatchRef = useRef<DispatchExporterHandle>(null);
  // TODO: [ANALYTICS] should we send a add request event
  const mutation = useMutation({
    mutationFn: async () => {
      let attributes: CartAnalyticsAttribute[] = [];
      try {
        attributes = await getAnalyticsEventAttributes(
          DEFAULT_PAYLOAD_ENRICHMENT_TIMEOUT
        );
      } catch (error) {
        Logger.error('Error in fetching cart attributes ', error);
      }
      // Logger.info("Express checkout client attributes", attributes)
      // TODO we should ideally get this from some config or encoded in widget itself
      attributes.push({
        key: 'checkoutPartner',
        value: 'SHOPIFY',
      } as CartAnalyticsAttribute);
      // TODO should use coins come from config or something
      return remixApi.expressCheckout({
        lines: [
          {
            quantity: 1,
            variantId: variant!.globalId,
            giftCardReceiver,
          } as any,
        ],
        useCoins: false,
        discountCodes: discountCodes ?? [],
        attributes,
        addressId,
      });
    },
    onSuccess: (cart: Cart) => {
      trackCartEvent(
        AnalyticsCartEventNames.CHECKOUT_BEGIN,
        enrichAnalyticsPayloadWithWidgetData<AnalyticsCartEventPayload>(
          { cart, isGuest: isAnonymous, isExpress: true },
          widget
        ),
        true
      );
      window.open(cart.checkoutUrl, '_self');
    },
    onError: (error) => {
      const title = getErrorMessage(
        error,
        {},
        'Unable to proceed to express buy'
      );
      Logger.error('Unable to proceed to express buy', error);
      showToast({ title });
    },
  });

  const onClick = () => {
    mutation.mutate();
    dispatchRef.current?.dispatch({
      type: ElemasonWidgetActionType.ANALYTICS,
      payload: {
        event: AnalyticsCartEventNames.CHECKOUT_BEGIN_BUTTON_CLICK,
        data: {
          quantity: 1,
          text: data?.text,
        },
      },
    });
    helperCtaRef.current?.click();
  };

  if (!variant) return null;

  return (
    <ElemasonAnalyticsProvider
      value={{
        ...analyticsContext,
        entities: {
          ...(analyticsContext.entities ?? {}),
          product,
          productVariant: variant,
        },
      }}
    >
      <ActionDispatchExporter ref={dispatchRef} />
      <Button
        data={data}
        onClick={onClick}
        config={config?.button}
        size={config?.button?.size}
        isLoading={mutation.isPending}
        variant={config?.button?.variant}
        modifier={config?.button?.modifier}
        className="tt-express-checkout-cta"
        style={{
          color: config?.button?.text?.color,
          borderColor: config?.button?.borderColor,
          borderRadius: config?.button?.borderRadius,
          backgroundColor: config?.button?.backgroundColor,
        }}
      />
      <span ref={helperCtaRef} className="tt-express-checkout-cta hidden" />
    </ElemasonAnalyticsProvider>
  );
};

export { ExpressCheckoutWidget };
