import { AnalyticsCartEventNames, trackCartEvent } from '@tectonic/analytics';
import {
  Button,
  enrichAnalyticsPayloadWithWidgetData,
  useElemasonContext,
  useHaloScript,
} from '@tectonic/elemason';
import { useRef } from 'react';

import type { AnalyticsCartEventPayload } from '@tectonic/analytics';
import type { Cart } from '@tectonic/types';
import type { FC } from 'react';
import type { CheckoutWidgetProps } from './Checkout.types';

const CheckoutWidget: FC<CheckoutWidgetProps> = ({ widget }) => {
  const { config } = widget;
  const wData = widget.data!;

  const cart = useHaloScript<Cart>(wData!.cart);
  const { currentUser } = useElemasonContext();
  const isAnonymous = !!currentUser?.isAnonymous;

  const btnRef = useRef<HTMLButtonElement>(null);
  const gtmHelperRef = useRef<HTMLSpanElement>(null);

  const onClick = () => {
    trackCartEvent(
      AnalyticsCartEventNames.CHECKOUT_BEGIN_BUTTON_CLICK,
      enrichAnalyticsPayloadWithWidgetData<AnalyticsCartEventPayload>(
        { cart, isGuest: isAnonymous, isExpress: false },
        widget
      ),
      true
    );

    btnRef.current?.click();
    gtmHelperRef.current?.click();
  };

  return (
    <>
      <Button
        data={wData.button}
        // magic-x prevents the button event propagation
        onClick={onClick}
        config={config?.button}
        size={config?.button?.size}
        variant={config?.button?.variant}
        modifier={config?.button?.modifier}
        style={{
          color: config?.button?.text?.color,
          borderColor: config?.button?.borderColor,
          borderRadius: config?.button?.borderRadius,
          backgroundColor: config?.button?.backgroundColor,
        }}
      />
      <span ref={gtmHelperRef} className="cart-checkout-btn hidden" />
      {/* Control for magic-x */}
      <button
        type="button"
        role="presentation"
        name="checkout"
        ref={btnRef}
        className="hidden"
      />
    </>
  );
};

CheckoutWidget.displayName = 'CheckoutWidget';

export { CheckoutWidget };
