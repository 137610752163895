import { Logger } from '@tectonic/logger';
import {
  getArticleUrl,
  removeParentBreadcrumb,
  sanitizeParentMeta,
} from './utils';

import type { DehydratedState } from '@tanstack/react-query';
import type { Article } from '@tectonic/types';
import type {
  MetaFunctionArgs,
  MetaFunctionUrls,
  SeoTemplates,
} from './meta.types';

const getMetadata = (dehydratedState: DehydratedState) => {
  // temp changes. solve it properly
  const queries = dehydratedState?.queries ?? [];
  const [aux, articleQuery] = queries;
  const article = (articleQuery?.state?.data as any)?.data?.article;
  return { article };
};

const getOgTags = (
  article: Article,
  seoTemplates: SeoTemplates,
  urls: MetaFunctionUrls
) => {
  try {
    const articleUrl = getArticleUrl(article, urls);

    const image = article.assetMap?.gallery?.[0];
    const imageTags = image?.cdnUrl
      ? [
          {
            property: 'og:image:secure_url',
            itemProp: 'image',
            content: image?.cdnUrl,
          },
          {
            property: 'og:image',
            itemProp: 'image',
            content: image?.cdnUrl,
          },
          {
            property: 'og:image:width',
            content: image?.dimensions?.width ?? '',
          },
          {
            property: 'og:image:height',
            content: image?.dimensions?.height ?? '',
          },
        ]
      : [];

    const title = article.seoFields?.title ?? article.title;
    // eslint-disable-next-line object-shorthand
    const titleTags = title ? [{ title: title }] : [];
    const keywordsTag = [
      {
        name: 'keywords',
        content: `${title}, Vaaree, vaaree.com`, // TODO solve this via seoTemplates
      },
    ];

    const description = article.seoFields?.description ?? '';
    const descriptionTags = [
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:description',
        content: description,
      },
    ];

    return [
      // {
      //   property: 'author',
      //   content: article.author?.name ?? '', // this should be same as root
      // },
      // there og tag for noindex should it be disabled also how to deal with deduplication
      { tagName: 'link', rel: 'canonical', href: articleUrl },
      ...titleTags,
      ...descriptionTags,
      ...keywordsTag,
      ...imageTags,
      { property: 'og:url', content: articleUrl },
      { property: 'og:title', content: title },
      { property: 'og:type', content: 'article' },
    ];
  } catch (e) {
    Logger.error(e, 'Error in generating og tags');
    return [];
  }
};

const getJsonLdTags = (article: Article) => {
  // console.log("articleJsonLds", article.seoFields)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, description, ...rest } = article.seoFields ?? {};

  // Only run for objects that are not arrays and not null
  return [...Object.values(rest ?? {})]
    .filter((ld) => typeof ld === 'object' && !Array.isArray(ld) && ld !== null)
    .map((ld) => ({ 'script:ld+json': ld }));
};

const articleMetaFunction = (
  args: MetaFunctionArgs,
  seoTemplates: SeoTemplates
) => {
  const { data, matches } = args;
  const { dehydratedState, urls } = data!;
  // console.log("article urls", urls);
  if (!dehydratedState) {
    return matches.flatMap((match) => match.meta);
  }
  let parentMeta = sanitizeParentMeta(matches).filter(
    (tag) => tag.rel !== 'canonical'
  );
  parentMeta = removeParentBreadcrumb(parentMeta);

  // console.log("parentMeta", parentMeta)

  const { article } = getMetadata(dehydratedState as DehydratedState);

  if (!article) {
    return matches.flatMap((match) => match.meta);
  }
  // console.log("article", article);
  const ogTags = getOgTags(article!, seoTemplates, urls);
  // console.log("articleogTags", ogTags);
  const jsonLdTags = getJsonLdTags(article);
  // console.log("articlejsonLdTags", jsonLdTags);
  return [...ogTags, ...parentMeta, ...jsonLdTags];
};

export { articleMetaFunction };
