import {
  useStyleConfig,
  useStyleConfigV2,
} from '@tectonic/elemason-components';
import { Carousel as UiCarousel } from '@tectonic/uikit';
import clsx from 'clsx';
import { debounce, isNil } from 'lodash-es';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useButtonConfig } from '../../../components';
import { ElemasonFragmentProvider } from '../../../contexts/ElemasonFragmentContext';
import { Cell } from '../../../core/Cell';
import { usePageFragment } from '../../../hooks';
import { useFragmentValue } from '../../../hooks/useFragmentValue';
import { CarouselSlide } from '../../CarouselBanner/CarouselSlide';

import type { CarouselHandle } from '@tectonic/uikit';
import type { ForwardRefRenderFunction } from 'react';
import type { GenericCarouselProps } from './GenericCarousel.types';

const GenericCarousel: ForwardRefRenderFunction<
  CarouselHandle,
  GenericCarouselProps
> = (
  { fragment, config, slides, onLoadMore, startIndex, onSlideChange, children },
  handleRef
) => {
  const startIndexRef = useRef(startIndex ?? 0);
  const pFragment = usePageFragment(fragment);
  const fragmentValue = useFragmentValue(pFragment);
  const carouselRef = useRef<CarouselHandle>(null);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(
    +(startIndex ?? 0)
  );

  const debouncedLoadMore = debounce(() => onLoadMore?.(), 1000);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const { style, className } = useStyleConfig(config?.dimension ?? {});
  const { style: paginationStyle, className: paginationClassName } =
    useStyleConfig(config?.pagination ?? {});

  const [scrollNextStyle, scrollNextClassName] = useStyleConfigV2(
    config?.arrows?.next?.container
  );
  const [scrollPrevStyle, scrollPrevClassName] = useStyleConfigV2(
    config?.arrows?.prev?.container
  );

  const prevButtonConfig = useButtonConfig(config?.arrows?.prev?.button ?? {});
  const nextButtonConfig = useButtonConfig(config?.arrows?.next?.button ?? {});

  const onSelect = useCallback(
    (index: number) => {
      if (!isNil(timeoutRef.current)) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setSelectedSlideIndex(index);
      }, 100);
    },
    [setSelectedSlideIndex]
  );

  useImperativeHandle(
    handleRef,
    () => {
      return {
        get emblaApi() {
          return carouselRef.current?.emblaApi;
        },
      };
    },
    [carouselRef]
  );

  useEffect(() => {
    carouselRef.current?.emblaApi?.on('scroll', (e) => {
      if (e.selectedScrollSnap() + 5 >= e.scrollSnapList().length) {
        debouncedLoadMore();
      }
    });
  }, [carouselRef.current]);

  useEffect(() => {
    onSlideChange?.(selectedSlideIndex);
  }, [selectedSlideIndex, onSlideChange]);

  return (
    <UiCarousel
      ref={carouselRef}
      style={style}
      inViewThreshold={1}
      axis={config?.axis}
      loop={config?.loop}
      onSelect={onSelect}
      startIndex={startIndexRef.current}
      slidesToScroll={config?.slidesToScroll}
      className={clsx('relative h-full w-full', className)}
      autoplay={{
        enable: config?.autoPlay,
        delay: config?.autoPlayInterval,
      }}
    >
      <UiCarousel.Slides>
        {(slides ?? [])?.map((item, index) => (
          <CarouselSlide
            key={index}
            config={config?.slide}
            className={clsx('relative h-fit w-full', className)}
          >
            <ElemasonFragmentProvider
              value={fragmentValue({
                scrollTo: (nIndex: number) => {
                  carouselRef.current?.emblaApi?.scrollTo(nIndex);
                },
                selectedSlideIndex,
                slide: item,
                slideIndex: index,
              })}
            >
              {pFragment?.cells.map((cell) => (
                <Cell key={cell.id} cell={cell} />
              ))}
            </ElemasonFragmentProvider>
          </CarouselSlide>
        ))}
      </UiCarousel.Slides>
      <UiCarousel.ScrollPrev
        style={prevButtonConfig.style}
        containerStyle={scrollPrevStyle}
        isVisible={config?.arrows?.visible}
        className={prevButtonConfig.className}
        containerClassName={scrollPrevClassName}
        startIcon={config?.arrows?.prev?.startIcon}
        {...prevButtonConfig.buttonProps}
      />
      <UiCarousel.ScrollNext
        style={nextButtonConfig.style}
        containerStyle={scrollNextStyle}
        isVisible={config?.arrows?.visible}
        className={nextButtonConfig.className}
        containerClassName={scrollNextClassName}
        startIcon={config?.arrows?.next?.startIcon}
        {...nextButtonConfig.buttonProps}
      />
      <UiCarousel.Dots
        style={paginationStyle}
        className={clsx(
          'flex',
          paginationClassName,
          config?.pagination?.overlay ? 'absolute' : ''
        )}
      >
        <UiCarousel.Dot
          className="transition-all"
          // @ts-expect-error TODO: fix types
          getStyles={({ isActive }: { isActive: boolean }) =>
            isActive
              ? config?.pagination?.activeDot
              : config?.pagination?.inactiveDot
          }
        />
      </UiCarousel.Dots>
      {children as any}
    </UiCarousel>
  );
};

GenericCarousel.displayName = 'GenericCarousel';

const ExoticCarousel = forwardRef(GenericCarousel);

export { ExoticCarousel as GenericCarousel };
