import { memo, useMemo } from 'react';

import type { Asset, ImageConfig } from '@tectonic/types';
import type { ComponentProps, FC } from 'react';

interface ImageAssetProps extends Omit<ComponentProps<'img'>, 'alt' | 'src'> {
  asset?: Asset;
  config?: ImageConfig;
}

const generateImageSrcSet = (
  cdnUrl: string,
  breakpoints: number[],
  coverage: number = 1
) => {
  const sources = breakpoints.map((breakpoint) => {
    const url = new URL(cdnUrl);
    url.searchParams.append(
      'width',
      Math.round(breakpoint * coverage).toString()
    );
    return `${url.toString()} ${Math.round(breakpoint)}w`;
  });

  return sources.join(', ');
};

const useAsset = ({
  asset,
  config,
  style,
}: Pick<ImageAssetProps, 'asset' | 'config' | 'style'>) =>
  useMemo(() => {
    if (!asset) {
      console.warn('Missing asset');
      return null;
    }

    const derivedStyles = {
      width: config?.width ?? style?.width,
      height: config?.height ?? style?.height,
      aspectRatio:
        config?.aspectRatio ||
        (style?.aspectRatio as number) ||
        (asset.dimensions
          ? asset.dimensions.width / asset.dimensions.height
          : undefined),
    };

    const srcSet = asset.cdnUrl
      ? generateImageSrcSet(
          asset.cdnUrl,
          [
            24, 32, 48, 64, 96, 144, 192, 213, 256, 320, 341, 384, 455, 480,
            512, 533, 576, 640, 683, 768, 768, 800, 1024, 1025, 1200,
          ],
          config?.coverage
        )
      : '';

    return { derivedStyles, srcSet };
  }, [asset, config, style]);

const ImageAsset: FC<ImageAssetProps> = ({
  asset,
  config,
  style,
  className,
  ...otherProps
}) => {
  const result = useAsset({ asset, config, style });

  if (!result) {
    return null;
  }

  const { derivedStyles, srcSet } = result;

  return (
    <div style={derivedStyles}>
      <img
        // TODO: temp change to log missing assets.
        alt={asset!.altText}
        className={className}
        loading={config?.loading ?? 'lazy'}
        style={{ ...style, ...derivedStyles, objectFit: config?.resizeMode }}
        srcSet={srcSet}
        sizes="100vw"
        {...otherProps}
      />
    </div>
  );
};

const ImageAssetMemo = memo(ImageAsset);

export { ImageAssetMemo as ImageAsset };
