import { Logger } from '@tectonic/logger';

import type {
  Asset,
  BackgroundImageConfig,
  GradientColorStop,
  GradientConfig,
} from '@tectonic/types';
import type { CSSProperties } from 'react';

const generateColorStops = (colorStops: GradientColorStop[]) =>
  colorStops
    .map(
      (stop) =>
        `${stop.color} ${
          Number.isFinite(stop.position) ? `${stop.position}%` : stop.position
        }`
    )
    .join(', ');

const generateGradientString = (gradient: GradientConfig) => {
  let gradientString;

  if (gradient.type === 'linear') {
    gradientString = `linear-gradient(${
      gradient.direction
    }, ${generateColorStops(gradient.colorStops)})`;
  } else if (gradient.type === 'radial') {
    gradientString = `radial-gradient(${gradient.shape} ${gradient.size} at ${
      gradient.position
    }, ${generateColorStops(gradient.colorStops)})`;
  } else {
    Logger.error('Unsupported gradient type');
    return '';
  }

  return gradientString;
};

const useBackgroundImageConfig = (config: BackgroundImageConfig) => {
  const backgroundStyles: CSSProperties = {};

  if (config.backgroundImage) {
    backgroundStyles.backgroundImage = `url(${(config.backgroundImage as Asset)
      ?.cdnUrl})`;
  }

  if (config.backgroundImageGradient) {
    backgroundStyles.backgroundImage = generateGradientString(
      config.backgroundImageGradient
    );
    backgroundStyles.backgroundSize = 'cover';
    backgroundStyles.backgroundPosition = 'center';
    backgroundStyles.backgroundRepeat = 'no-repeat';
  }

  return backgroundStyles;
};

export { useBackgroundImageConfig };
