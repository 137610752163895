const razorpayMagicShopifyCheckoutConfig = {
  status: 'live',
  isShippingEngineEnabled: true,
  isEmailMandatory: true,
  isLoginMandatory: true,
  permalinksFlow: true,
  merchantKey: 'rzp_live_iutJcMjxftLyxl',
  merchantName: 'VAAREE',
  merchantThemeColor: '#FFC845',
  // New sdk config below
  isForceV2: true,
  isCleverTapEnabled: true,
};

export { razorpayMagicShopifyCheckoutConfig };
