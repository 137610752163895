import { cssBundleHref } from '@remix-run/css-bundle';
import { useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Logger } from '@tectonic/logger';
import { RootApp, loaderGen } from '@tectonic/remix-core';
import { generateTWColors, loadScript } from '@tectonic/utils';
import { isEmpty } from 'lodash-es';
import { memo, useEffect, useRef, type FC } from 'react';
import { colors } from '../colors';
import mainSvg from '../public/main.svg';
import routes from '../routes.json';
import { razorpayMagicShopifyCheckoutConfig } from './razorpay';
import mainCss from './styles/main.css';
import { widgets } from './widgets';

import type { LinksFunction, MetaFunction } from '@remix-run/node';
import type { CartLine } from '@tectonic/types';

const deltaXConfig = {
  xb: '35CmX6113',
  event: 'init',
  domain: 'my.vaaree.com',
  supportNonPaidEvent: true
};

// TODO Check if the below can be retreived from env or something
const AFFILIATE_GTM_ID = 'GTM-M7SH337B';

const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];


const title = `Buy Best Home Decor Items & Essentials Online At Best Prices - Vaaree`;
const description = `Home Décor- Buy Home Decorative Items & essentials Online in India. Choose from a wide range of premium home decor products at Vaaree. Buy house decoration items online at best prices.`;

const meta: MetaFunction = ({ data }) => {
  return [
  {
    title: title
  },
  { name: 'description', content: description },

  {
    name: 'keywords',
    content: 'buy home decor, bedsheets, towels, kitchen, mugs online'
  },
  {
    name: 'author',
    content: 'House of Vaaree'
  },
  {
    property: 'og:site_name',
    content: 'Vaaree'
  },
  {
    property: 'og:url',
    content: 'https://vaaree.com/'
  },
  {
    property: 'og:title',
    content: title
  },
  {
    property: 'og:type',
    content: 'website'
  },
  { property: 'og:description', content: description },
  {
    property: 'og:image',
    content:
    'https://vaaree.com/cdn/shop/files/WhatsApp_Image_2022-05-24_at_4.32.18_PM.jpg?v=1719326801'
  },
  {
    property: 'og:image:secure_url',
    content:
    'https://vaaree.com/cdn/shop/files/WhatsApp_Image_2022-05-24_at_4.32.18_PM.jpg?v=1719326801'
  },
  {
    property: 'og:image:width',
    content: '512'
  },
  {
    property: 'og:image:height',
    content: '512'
  },
  {
    property: 'twitter:site',
    content: '@vaareehome'
  },
  {
    property: 'twitter:card',
    content: 'summary_large_image'
  },
  {
    tagName: 'link',
    rel: 'canonical',
    href: 'https://vaaree.com/'
  },
  {
    name: 'facebook-domain-verification',
    content: 'xdlnd89oajo8ige0bstdecpvqupqa8'
  },
  {
    id: 'shopify-digital-wallet',
    name: 'shopify-digital-wallet',
    content: '/63225266422/digital_wallets/dialog'
  },
  {
    'script:ld+json': {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://vaaree.com'
      }]

    }
  },
  {
    'script:ld+json': {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: 'https://vaaree.com',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://vaaree.com/search?q={search_term_string}',
        'query-input': 'required name=search_term_string'
      }
    }
  },
  {
    'script:ld+json': {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Vaaree',
      url: 'https://vaaree.com/',
      logo: 'https://vaaree.com/cdn/shop/files/apple-icon-152x152_55x.png?v=1674062412',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '08068084868',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en'
      },
      sameAs: [
      'https://www.facebook.com/vaareehome',
      'https://twitter.com/vaareehome',
      'https://www.instagram.com/vaareehome/',
      'https://www.youtube.com/channel/UCGW-YhVqqn11TgamrNNPVqw',
      'https://in.linkedin.com/company/vaareehome',
      'https://www.pinterest.ca/vaareehome/']

    }
  }];

};

const Body: FC = memo(() => {
  const mgxRef = useRef<any>(null);

  // DeltaX Effect
  useEffect(() => {
    globalThis.dxUni = globalThis.dxUni ?? [];
    globalThis.dxUni.push(deltaXConfig);
    globalThis.xb = deltaXConfig.xb;
    globalThis.create_xcart_string = (lines: CartLine[]) => {
      return lines.
      map((line) => {
        // TODO: add fields as here as needed.
        return [].join(';');
      }).
      join('|');
    };
    loadScript('https://s.path.promo/lp.js', { async: true });
  }, []);

  useEffect(() => {
    if (isEmpty(AFFILIATE_GTM_ID)) {
      return;
    }
    const GTM_JS_URL = `https://www.googletagmanager.com/gtm.js?id=${AFFILIATE_GTM_ID}&l=affiliateGTMDataLayer`;
    globalThis.affiliateGTMDataLayer = globalThis.affiliateGTMDataLayer ?? [];
    globalThis.affiliateGtag = function affiliateGtagFn() {
      // eslint-disable-next-line prefer-rest-params
      globalThis.affiliateGTMDataLayer.push(arguments);
    };
    globalThis.affiliateGtag('consent', 'default', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
    loadScript(GTM_JS_URL);
    globalThis.affiliateGTMDataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
  }, []);

  useEffect(() => {
    loadScript(
      'https://magic-plugins.razorpay.com/sopc/magic-sopc.js',
      { defer: true },
      mgxRef.current
    );
  }, [mgxRef]);

  return (
    <>
      <div id="magic-checkout-data-container" ref={mgxRef}>
        <script
          id="RazorpayMagicSopcConfig"
          type="application/json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(razorpayMagicShopifyCheckoutConfig)
          }} />

      </div>
    </>);

});

const App = () =>
<RootApp
  routes={routes}
  svgHref={mainSvg}
  colors={generateTWColors(colors)}
  body={<Body />}
  widgets={widgets} />;



const loader = loaderGen(mainCss);

export { links, loader };

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  Logger.error(error);
  return <div>Something went wrong</div>;
};

export { meta };

export default App;