import { isEqual } from 'lodash-es';
import { memo, useCallback, useMemo, useRef, useState, type FC } from 'react';
import { useHaloScript } from '../../hooks/useHaloScript';
import { GenericCarousel } from '../containers/GenericCarousel';

import type { ElemasonGenericCarouselWidget } from '@tectonic/types';
import { CarouselHandle } from '@tectonic/uikit';
import { ElemasonFragmentProvider } from '../../contexts';
import { Cell } from '../../core';
import {
  useActionDispatch,
  useFragmentValue,
  usePageFragment,
} from '../../hooks';

interface GenericCarouselProps {
  widget: ElemasonGenericCarouselWidget;
}

const GenericCarouselWidget: FC<GenericCarouselProps> = ({
  widget: { config, data, actions },
}) => {
  const list = useHaloScript(data?.list) as Array<unknown>;
  const startIndex = useHaloScript(data?.startIndex);
  const listRef = useRef(list);
  const dispatchRef = useRef<ReturnType<typeof useActionDispatch>>();
  dispatchRef.current = useActionDispatch();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const oFragment = usePageFragment(data?.overlayFragment);
  const fragmentValue = useFragmentValue(oFragment);

  const mList = useMemo(() => {
    if (isEqual(listRef.current, list)) {
      return listRef.current;
    }
    listRef.current = list;
    return listRef.current;
  }, [listRef, list]);

  const onSlideChange = useCallback(
    (index: number) => {
      actions?.onSlideChange?.forEach((action) =>
        dispatchRef.current!(action, index)
      );
      setActiveIndex(index);
    },
    [dispatchRef]
  );

  const carouselRef = useRef<CarouselHandle>(null);

  return (
    <GenericCarousel
      ref={carouselRef}
      slides={mList}
      fragment={data?.fragment}
      config={config?.carousel}
      startIndex={startIndex}
      onSlideChange={onSlideChange}
    >
      <ElemasonFragmentProvider
        value={fragmentValue({
          slide: mList,
          scrollTo: (nIndex: number) => {
            carouselRef.current?.emblaApi?.scrollTo(nIndex);
          },
        })}
      >
        {oFragment?.cells.map((cell) => <Cell key={cell.id} cell={cell} />)}
      </ElemasonFragmentProvider>
    </GenericCarousel>
  );
};

const MemoizedGenericCarouselWidget = memo(GenericCarouselWidget);

export { MemoizedGenericCarouselWidget as GenericCarouselWidget };
